import "../styles/globals.css";
import "../styles/verification.css";
import Head from "next/head";
import { ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import theme from "../src/theme";
import Router from "next/router";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import CartRoomContextProvider from "../src/context/CartRoom";
import CartFoodContextProvider, {
  CartFoodContext,
} from "../src/context/CartFood";
import CartSouvenirContextProvider, {
  CartSouvenirContext,
} from "../src/context/CartSouvenir";
import React, { useContext, useEffect } from "react";
import { Toaster } from "react-hot-toast";
import AppContextProvider from "../src/context/AppContext";
import ErrorPage from "../src/components/ErrorPage";
import withClearCache from "../src/clearCache";

Router.events.on("routeChangeStart", () => {
  NProgress.start();
});
Router.events.on("routeChangeComplete", () => NProgress.done());
Router.events.on("routeChangeError", () => NProgress.done());

function Index(props) {
  const { restoreCart } = useContext(CartFoodContext);
  const { restoreSouvenirCart } = useContext(CartSouvenirContext);

  useEffect(() => {
    restoreCart();
    restoreSouvenirCart();
  }, []);

  return <div>{props.children}</div>;
}

const ClearCacheComponent = withClearCache(App);

function MyApp(props) {
  return <ClearCacheComponent {...props} />;
}

function App({ Component, pageProps }) {
  return (
    <AppContextProvider>
      <CartFoodContextProvider>
        <CartRoomContextProvider>
          <CartSouvenirContextProvider>
            <Head>
              <title>{process.env.NEXT_PUBLIC_NAME || "Pesan.io"}</title>
              <meta name="theme-color" content={theme.palette.primary.main} />
              <link
                rel="icon"
                href={
                  process.env.NEXT_PUBLIC_SITE_FAVICON ||
                  "https://swaps-bucket.s3.amazonaws.com/Frame%2020.png-2022-08-30T06%3A33%3A50.880Z"
                }
              />
            </Head>
            <ThemeProvider theme={theme}>
              <CssBaseline />

              <Index>
                <Toaster />
                <ErrorPage>
                  <Component {...pageProps} />
                </ErrorPage>
              </Index>
            </ThemeProvider>
          </CartSouvenirContextProvider>
        </CartRoomContextProvider>
      </CartFoodContextProvider>
    </AppContextProvider>
  );
}

export default MyApp;
