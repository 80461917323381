import {
  Box,
  Button,
  Container,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React, { Fragment } from "react";
import Error400 from "../../public/img/Error400";
import Error404 from "../../public/img/Error404";
import Error500 from "../../public/img/Error500";
import { useTranslation } from "next-export-i18n";
import { useApp } from "../context/AppContext";
import { axiosInstance } from "../config/axios";

const useStyles = makeStyles({
  root: {
    paddingLeft: 0,
    paddingRight: 0,
    height: "100%",
    minHeight: "100vh",
    backgroundColor: "#fff",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  wrapper: {
    height: "100%",
    padding: 24,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  errorMessage: {
    color: "#A0A4A8",
    marginTop: 40,
    fontSize: 14,
    textAlign: "center",
  },
});

interface Props {
  error?: number;
  errorMessage?: string;
  children?: React.ReactNode;
}

function ErrorPage(props: Props) {
  const { error, errorMessage, children } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const { errorResponse, setErrorResponse } = useApp();

  axiosInstance.interceptors.request.use((config) => {
    const token = localStorage.getItem("token");

    config.headers = Object.assign(
      {
        Authorization: `Bearer ${token}`,
      },
      config.headers
    );
    return config;
  });
  axiosInstance.interceptors.response.use(
    function (response: any) {
      return response;
    },
    function (error: any) {
      if (
        error?.response?.data?.meta?.error_code === "INVALID_TENANT_IDENTIFIER"
      ) {
        setErrorResponse(error?.response?.data?.meta);
      }
      return Promise.reject(error);
    }
  );

  const content = () => {
    switch (error || errorResponse.status_code) {
      case 400:
        return (
          <Fragment>
            <Error400 />
            <Typography className={classes.errorMessage}>
              {errorResponse.error_code === "INVALID_TENANT_IDENTIFIER"
                ? t("error-page.invalid-tenant-identifier")
                : errorMessage}
            </Typography>
          </Fragment>
        );

      case 404:
        return (
          <Fragment>
            <Error404 />
            <Typography className={classes.errorMessage}>
              {errorResponse.error_code === "INVALID_TENANT_IDENTIFIER"
                ? t("error-page.invalid-tenant-identifier")
                : t("error-page.not-found-404")}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              style={{
                fontWeight: 700,
                marginTop: 24,
                boxShadow: "none !important",
              }}
              onClick={() => {
                window.open(
                  `https://wa.me/${
                    process.env.WHATSAPP_DEFAULT || "6288802870057"
                  }`,
                  "_blank"
                );
              }}
            >
              {t("error-page.contact-support")}
            </Button>
          </Fragment>
        );
      case 500:
        return (
          <Fragment>
            <Error500 />
            <Typography className={classes.errorMessage}>
              {t("error-page.internal-server-error-500")}
            </Typography>
          </Fragment>
        );
    }
  };

  return (
    <Fragment>
      {error || errorResponse.status_code ? (
        <Container maxWidth="xs" className={classes.root}>
          <Box className={classes.wrapper}>{content()}</Box>
        </Container>
      ) : (
        children
      )}
    </Fragment>
  );
}

export default ErrorPage;
