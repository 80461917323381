import { createContext, useState, useEffect } from "react";
import { SouvenirDto } from "../libs/souvenirs";

export interface CartSouvenirContextType {
  souvenirPrice: number;
  increaseCart: any;
  decreaseCart: any;
  souvenirCartUpdated: boolean;
  souvenirCart: SouvenirDto[];
  addCart: (list: any) => void;
  restoreDefault: () => void;
  restoreSouvenirCart: () => void;
  calculateTotal: (list: any) => void;
  addNote: (id: string | number, note: string) => void;
}

export const CartSouvenirContext = createContext<CartSouvenirContextType>(
  {} as never
);

const CartSouvenirContextProvider = ({ children }) => {
  const [souvenirCart, setSouvenirCart] = useState([]);
  const [souvenirPrice, setSouvenirPrice] = useState(0);
  const [souvenirCartUpdated, setSouvenirCartUpdated] = useState(true);

  const restoreDefault = () => {
    setSouvenirCart([]);
    setSouvenirPrice(0);
    localStorage.setItem("souvenirCart", JSON.stringify([]));
    localStorage.setItem("souvenirPrice", JSON.stringify(0));
  };

  const restoreSouvenirCart = () => {
    const cartData = localStorage.getItem("souvenirCart");
    const priceData = JSON.parse(localStorage.getItem("souvenirPrice"));
    if (cartData) {
      setSouvenirCart(JSON.parse(cartData));
      setSouvenirPrice(priceData);
    }
  };

  const calculateTotal = (list) => {
    if (list.length > 0) {
      const sum = (item) => item.reduce((x, y) => x + y);
      let total = sum(list.map((product) => Number(product.totalPrice)));
      setSouvenirPrice(total);

      localStorage.setItem("souvenirPrice", total);
      setSouvenirCartUpdated(!souvenirCartUpdated);
    } else {
      setSouvenirPrice(0);
      localStorage.setItem("souvenirPrice", JSON.stringify(0));
      setSouvenirCartUpdated(!souvenirCartUpdated);
    }
  };

  const addCart = (item: any) => {
    const cartData = [
      ...souvenirCart,
      { ...item, total: 1, totalPrice: item.price },
    ];
    setSouvenirCart(cartData);
    calculateTotal(cartData);
    localStorage.setItem("souvenirCart", JSON.stringify(cartData));
  };

  const increaseCart = (item: any) => {
    const objIndex = souvenirCart.findIndex((select) => {
      return select.id === item.id;
    });
    let cartData = souvenirCart;
    cartData[objIndex].total += 1;
    cartData[objIndex].totalPrice =
      cartData[objIndex].total * cartData[objIndex].price;
    setSouvenirCart(cartData);
    calculateTotal(cartData);
    localStorage.setItem("souvenirCart", JSON.stringify(cartData));
  };

  const decreaseCart = (item: any) => {
    const objIndex = souvenirCart.findIndex((select) => {
      return select.id === item.id;
    });
    let cartData = souvenirCart;
    if (cartData[objIndex].total > 1) {
      cartData[objIndex].total -= 1;
      cartData[objIndex].totalPrice =
        cartData[objIndex].total * cartData[objIndex].price;
      setSouvenirCart(cartData);
      calculateTotal(cartData);
      localStorage.setItem("souvenirCart", JSON.stringify(cartData));
    } else {
      const newCart = souvenirCart.filter((obj) => {
        return obj.id !== item.id;
      });
      setSouvenirCart(newCart);
      calculateTotal(newCart);
      localStorage.setItem("souvenirCart", JSON.stringify(newCart));
    }
  };

  const addNote = (id: string | number, note) => {
    let cartData = [];
    souvenirCart.map((item) => {
      if (item.id === id) {
        item.note = note;
      }
      cartData.push(item);
    });
    setSouvenirCart(cartData);
    localStorage.setItem("souvenirCart", JSON.stringify(cartData));
  };

  return (
    <CartSouvenirContext.Provider
      value={{
        souvenirCart,
        souvenirPrice,
        restoreDefault,
        restoreSouvenirCart,
        calculateTotal,
        addCart,
        increaseCart,
        decreaseCart,
        addNote,
        souvenirCartUpdated,
      }}
    >
      {children}
    </CartSouvenirContext.Provider>
  );
};

export default CartSouvenirContextProvider;
