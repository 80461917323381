import { createTheme } from "@material-ui/core/styles";
import { red } from "@material-ui/core/colors";

// Create a theme instance.
const theme = createTheme({
  palette: {
    primary: {
      main: `${process.env.PRIMARY_COLOR || "#2F9CF1"}`,
      contrastText: "#fff",
    },
    secondary: {
      main: "#ee4746",
      contrastText: "#fff",
    },
    error: {
      main: red.A400,
    },
    background: {
      default: "#fff",
    },
  },
  breakpoints: {
    values: {
      xs: 320,
      sm: 425,
      md: 768,
      lg: 1024,
      xl: 1440,
    },
  },
  typography: {
    fontFamily: "'Lato', 'sans-serif'",
    button: {
      textTransform: "none",
    },
  },
  overrides: {
    MuiAccordion: {
      root: {
        margin: "0px !important",
        "&:before": {
          backgroundColor: "none",
        },
      },
    },
    MuiAccordionSummary: {
      root: {
        padding: "0px 20px",
      },
    },
    MuiContainer: {
      root: {
        paddingLeft: "0px !important",
        paddingRight: "0px !important",
      },
    },
  },
});

export default theme;
