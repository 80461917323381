/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";

interface ErrorResponseDto {
  error_code: string;
  errors: string[];
  message: string;
  status_code: number;
}

interface AppContextState {
  errorResponse: ErrorResponseDto;
}

export interface AppContextValue {
  errorResponse: ErrorResponseDto;
  setErrorResponse: (value: any) => void;
}

const AppContext = React.createContext<AppContextValue>({} as never);

export default function AppContextProvider({ children }) {
  const [{ errorResponse }, updateState] = useState<AppContextState>({
    errorResponse: {
      error_code: "",
      errors: [],
      message: "",
      status_code: 0,
    },
  });

  const setErrorResponse = (error) => {
    updateState((prevState) => ({
      ...prevState,
      errorResponse: error,
    }));
  };

  return (
    <AppContext.Provider
      value={{
        errorResponse,
        setErrorResponse,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}

export const useApp = () => React.useContext(AppContext);
