var en = require("./translations.en.json");
var id = require("./translations.id.json");

const i18n = {
  translations: {
    en: en.i18n,
    id: id.i18n,
  },
  defaultLang: "id",
};

module.exports = i18n;
