import React, { createContext, useState, useEffect } from "react";
import { RatePlanDto, RoomDto } from "../libs/rooms";

export interface CartRoomContextType {
  cart: RoomDto[];
  cartOrderRoom: {
    totalRoom: number;
    totalPrice: number;
    hotelName: string;
  };
  setCart: (data) => void;
  PlusOrderQty: (roomId: string, ratePlanCode: string, data: RoomDto) => void;
  MinOrderQty: (roomId: string, ratePlanCode: string) => void;
  settingCart: (data) => void;
  resetCart: () => void;
}

const getTotalRomm = (cart: RoomDto[]): number => {
  if (cart.length === 0) {
    return 0;
  }
  let totalData: number = 0;
  cart.forEach((value) => {
    totalData += value.order;
  });
  return totalData;
};

export const CartRoomContext = createContext<CartRoomContextType>({} as never);

const CartRoomContextProvider = ({ children }) => {
  const [cart, setCart] = useState<RoomDto[]>([]);
  const [cartOrderRoom, setCartOrderRoom] = useState({
    totalRoom: 0,
    totalPrice: 0,
    hotelName: "",
  });

  const getTotalPrice = () => {
    const cartRooms = cart.filter((val) => val.order !== 0);
    let cartRatePlans: RatePlanDto[] = [];
    cartRooms.forEach((room) => {
      const ratePlans = room.ratePlans.filter((val) => val.order !== 0);
      ratePlans.forEach((ratePlan) => {
        cartRatePlans = [...cartRatePlans, ratePlan];
      });
    });
    let totalPrice = 0;
    cartRatePlans.forEach((res) => {
      totalPrice = totalPrice + res.price * res.order;
    });
    return totalPrice;
  };

  const settingCart = (data) => {
    let rooms: RoomDto[] = [];
    data.forEach((res, i) => {
      rooms = [...rooms, { ...data[i], order: 0 }];
    });
    return rooms;
  };

  const resetCart = () => {
    setCartOrderRoom({
      totalRoom: 0,
      totalPrice: 0,
      hotelName: "",
    });
  };

  useEffect(() => {
    if (localStorage.getItem("hotel_data")) {
      const hotelData = JSON.parse(localStorage.getItem("hotel_data") || "{}");
      setCartOrderRoom({
        ...cartOrderRoom,
        hotelName: hotelData?.hotelName || "",
        totalPrice: getTotalPrice(),
        totalRoom: getTotalRomm(cart.filter((val) => val.order !== 0)),
      });
    }
  }, [cart]);

  useEffect(() => {
    if (cartOrderRoom.totalRoom !== 0) {
      localStorage.setItem("cart_order_room", JSON.stringify(cart));
    }
  }, [cart, cartOrderRoom]);

  const PlusOrderQty = (roomId: string, ratePlanCode: string, data) => {
    const selectedRoomTypeIndex = cart.findIndex(
      (val) => val.roomId === roomId
    );
    const selectedRoomType = cart[selectedRoomTypeIndex];

    const currentRatePlanIndex = selectedRoomType?.ratePlans?.findIndex(
      (ratePlan) => ratePlan.code === ratePlanCode
    );

    if (selectedRoomType.order < data?.qty) {
      const newCart = [...cart];
      newCart[selectedRoomTypeIndex].order += 1;
      newCart[selectedRoomTypeIndex].ratePlans[currentRatePlanIndex].order += 1;
      setCart([...newCart]);
    }
  };

  const MinOrderQty = (roomId: string, ratePlanCode: string) => {
    const selectedRoomTypeIndex = cart.findIndex(
      (val) => val.roomId === roomId
    );
    const selectedRoomType = cart[selectedRoomTypeIndex];

    const currentRatePlanIndex = selectedRoomType?.ratePlans?.findIndex(
      (ratePlan) => ratePlan.code === ratePlanCode
    );

    if (selectedRoomType.order > 0) {
      const newCart = [...cart];
      newCart[selectedRoomTypeIndex].order -= 1;
      newCart[selectedRoomTypeIndex].ratePlans[currentRatePlanIndex].order -= 1;
      setCart([...newCart]);
    }
  };

  const value = {
    cart,
    setCart,
    cartOrderRoom,
    PlusOrderQty,
    MinOrderQty,
    settingCart,
    resetCart,
  };

  return (
    <CartRoomContext.Provider value={value}>
      {children}
    </CartRoomContext.Provider>
  );
};

export default CartRoomContextProvider;
